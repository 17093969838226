import * as React from "react";
import ImageGallery from "react-image-gallery";
import Layout from "../components/layout";
import Seo from "../components/seo";
import Footer from "../components/FooterSection";
import Breadcrumb from "../components/Breadcrumb";
import BannerSection from "../components/BannerSection";
import { graphql, useStaticQuery } from "gatsby";
import { DeepNonNullable } from "ts-essentials";
import { AboutUsDataQuery } from "../../graphqlTypes";
import { GatsbyImage, IGatsbyImageData } from "gatsby-plugin-image";
import { MDXRenderer } from "gatsby-plugin-mdx";
import { mdxBody } from "@utils/mdxUtils";
import { MDXProvider } from "@mdx-js/react";
import { CustomMarkdownComponents } from "@utils/markdownRendering";

const AboutPage = () => {
  const data = useStaticQuery<DeepNonNullable<AboutUsDataQuery>>(graphql`
    query AboutUsData {
      strapiAboutUs {
        title
        pageTitle
        childStrapiAboutUsText {
          childMdx {
            body
          }
        }
      }
    }
  `);

  const { pageTitle, childStrapiAboutUsText: text, title } = data.strapiAboutUs;

  return (
    <Layout>
      <Seo title={pageTitle} />
      <div className="flex flex-col max-w-7xl md:mx-auto px-4 sm:px-6 mt-4 mb-4">
        <Breadcrumb title={title} />
        <div className="grid grid-cols-5 gap-4">
          <div className="col-start-1 col-end-6 md:col-end-5">
            <h1 className="blogpost font-sans text-white font-semibold mt-6 mb-10">
              {title}
            </h1>
          </div>
        </div>
      </div>
      <div className="full-width-strip bg-accuRankerCream">
        <div className="max-w-7xl mx-5 md:mx-auto py-12 pt-5 px-4 sm:px-6">
          <div className="mb-20 flex flex-col md:flex-row justify-between gap-10">
            <div>
              <MDXProvider components={CustomMarkdownComponents}>
                <MDXRenderer>{mdxBody(text)}</MDXRenderer>
              </MDXProvider>
              {/*<ImageGallery*/}
              {/*  renderItem={item => {*/}
              {/*    return (*/}
              {/*      <div className="flex flex-col gap-y-5 text-left">*/}
              {/*        <GatsbyImage*/}
              {/*          image={item.original as unknown as IGatsbyImageData}*/}
              {/*          alt={item.originalTitle || "Gallery Image"}*/}
              {/*          objectFit="contain"*/}
              {/*        />*/}
              {/*        <p className="text-gray-400 mb-0">{item.originalTitle}</p>*/}
              {/*        <div className="border border-black opacity-10 m-0 h-0.5"></div>*/}
              {/*      </div>*/}
              {/*    );*/}
              {/*  }}*/}
              {/*  showBullets*/}
              {/*  showNav={false}*/}
              {/*  showFullscreenButton={false}*/}
              {/*  showPlayButton={false}*/}
              {/*  showThumbnails={false}*/}
              {/*  items={images}*/}
              {/*/>*/}
            </div>
          </div>
          {/*<BannerSection />*/}
        </div>
      </div>
      <Footer />
    </Layout>
  );
};

export default AboutPage;
